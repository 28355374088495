.body {
  display: flex;
  flex-direction: row;
  padding-top: 9rem;
}

.navSection {
  width: 8%;
  background-color: rgb(12, 36, 14);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.pageSection {
  width: 100%;
  display: block;
  padding-left: 15%;
}
