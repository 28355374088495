.loader {
  width: 30%;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-weight: 800;
  text-align: center;
  justify-content: center;
  align-items: center;
}
